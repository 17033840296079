.ngx-datatable.bootstrap.custom-datatable .datatable-header .datatable-header-cell {
  @extend .px-2;
  // @extend .py-3;
  font-size: $font-size-base;
  vertical-align: middle;
}

.ngx-datatable.bootstrap.custom-datatable .datatable-body .datatable-body-row .datatable-body-cell .datatable-body-cell-label>div {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  hyphens: auto !important;
  text-overflow: ellipsis;
}

.ngx-datatable.bootstrap.custom-datatable .datatable-body .datatable-body-row .datatable-body-cell {
  @extend .p-1;
}

.ngx-datatable.bootstrap {
  font-size: $font-size-base;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #0292dc;

  .mwoc-name {
    color: #dddddd;
  }
}

.ngx-datatable.bootstrap:not(.inventory) .datatable-body .datatable-body-row:hover {
  color: #dddddd;
  background-color: #0292dc !important;

  a, .mwoc-name {
    color: #dddddd;
  }

  button {
    color: #dddddd;
  }
}

.ngx-datatable.bootstrap.referal-item .datatable-body .datatable-body-row.active.datatable-row-even {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgb(21, 27, 30);
}

.ngx-datatable.bootstrap.referal-item .datatable-body .datatable-body-row.active.datatable-row-odd {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(21, 27, 30);
}

// .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
//   overflow: initial !important;
// }

/* ---- Datatable in Claim Page ---- */

#claim.ngx-datatable .datatable-header-cell-label,
#claim.ngx-datatable .datatable-body-cell-label {
  white-space: normal !important;
  word-break: break-all !important;
  font-size: 0.75rem;
}

.ngx-datatable {
  .datatable-header {
    .datatable-header-cell {
      .datatable-header-cell-template-wrap {
        @extend .pl-1;
      }
    }
  }
}

.ngx-datatable.bootstrap.history-tab-visit-datatable {
  .datatable-body-cell {
    border-left: 1px solid #cdcdcd;
    border-right: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
  }

  .datatable-body-row {
    background-color: #f5f7fa !important
  }
}

.ngx-datatable.bootstrap.history-tab-drug-datatable {
  .datatable-header {
    height: 0px !important;
  }

  .datatable-body-cell {
    border: 0;
  }

  .datatable-body-row {
    background-color: #ffffff !important
  }
}

.white-space-pre {
  white-space: pre !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.ow {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

// inventory
.ngx-datatable.bootstrap.inventory .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.3rem
}

.ngx-datatable.bootstrap.inventory.stock-list .datatable-header {
  height: 55px !important;
}

.ngx-datatable.material.inventory.stock-list .datatable-header .datatable-header-cell {
  font-size: 14px !important;
  padding-top: 0 !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.ngx-datatable.material.inventory .datatable-body .datatable-body-row .datatable-body-cell {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}