$header-height:3rem;

.fixed-div-consultation-add {
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9;
  height: -webkit-max-content;
  background-color: white;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.fixed-div {
  height: $header-height;
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 1;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.fixed-div-patient-list {
  height: $header-height;
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 1;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.fixed-div-padding-top {
  padding-top: calc(#{$header-height} + 0.5rem) !important;
}

.fixed-div-patient-list button {
  position: fixed;
  margin-top: 6px;
  right: 25px;
}

.sidebar-fixed {
  .fixed-div-consultation-add {
    margin-left: $sidebar-width;
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-minimized {
    &.sidebar-fixed {
      .fixed-div-consultation-add {
        margin-left: $sidebar-minimized-width;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .fixed-div-consultation-add {
    margin-left: 0 !important;
  }

  .sidebar-mobile-show {
    .fixed-div-consultation-add {
      margin-right: - $mobile-sidebar-width !important;
      margin-left: $mobile-sidebar-width !important;
    }
  }
}


/* 1100px and below */

@media screen and (max-width: 1100px) {
  .sidebar-minimized {
    &.sidebar-fixed {
      @include header-info-font-reduce-by(5px);
    }
  }

  .sidebar-fixed {
    @include header-info-font-reduce-by(6px);
  }
}


/* 1100px and above */

@media screen and (min-width: 1100px) {
  .sidebar-minimized {
    &.sidebar-fixed {
      @include header-info-font-reduce-by(4px);
    }
  }

  .sidebar-fixed {
    @include header-info-font-reduce-by(6px);
  }
}


/* 1200 and above */

@media screen and (min-width: 1200px) {
  .sidebar-minimized {
    &.sidebar-fixed {
      @include header-info-font-reduce-by(2px);
    }
  }

  .sidebar-fixed {
    @include header-info-font-reduce-by(4px);
  }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media screen and (min-width: 1398px) {
  .sidebar-minimized {
    &.sidebar-fixed {
      @include header-info-font-reduce-by(0px);
    }
  }

  .sidebar-fixed {
    @include header-info-font-reduce-by(2px);
  }
}
