@use "sass:math";

// IE10&11 Flexbox fix
@media all and (-ms-high-contrast: none) {
    html {
        display: flex;
        flex-direction: column;
    }
}

// app-dashboard and app-root are Angular2+ selectors. You can add here your own selectors if you need.
.app,
app-dashboard,
app-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-header {
    flex: 0 0 $navbar-height;
}

.app-footer {
    flex: 0 0 $footer-height;
}

.app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow-x: hidden;

    .main {
        flex: 1;
        min-width: 0;
    }

    .sidebar {
        // $sidebar-width is the width of the columns
        flex: 0 0 $sidebar-width;
        // put the nav on the left
        order: -1;
    }

    .aside-menu {
        // $aside-menu-width is the width of the columns
        flex: 0 0 $aside-menu-width;
    }
}

//
// header
//
.header-fixed {
    .app-header {
        position: fixed;
        z-index: $zindex-sticky;
        width: 100%;
    }
    .app-body {
        margin-top: $navbar-height;
    }
}

//
// Sidebar
//
.sidebar-hidden {
    .sidebar {
        margin-left: - $sidebar-width;
    }
}

.sidebar-fixed {
    .sidebar {
        position: fixed;
        z-index: $zindex-sticky - 1;
        width: $sidebar-width;
        height: calc(100vh - #{$navbar-height});
        // margin-top: - $navbar-height;

        // .sidebar-nav {
        //   height: calc(100vh - #{$navbar-height});
        // }
    }

    .main,
    .app-footer {
        margin-left: $sidebar-width;
    }

    &.sidebar-hidden {
        .main,
        .app-footer {
            margin-left: 0;
        }
    }
}

.sidebar-off-canvas {
    .sidebar {
        position: fixed;
        z-index: $zindex-sticky - 1;
        height: 100%;

        .sidebar-nav {
            height: calc(100vh - #{$navbar-height});
        }
    }
}

@include media-breakpoint-up(lg) {
    .sidebar-compact {
        .sidebar {
            flex: 0 0 $sidebar-compact-width;
        }

        &.sidebar-hidden {
            .sidebar {
                margin-left: - $sidebar-compact-width;
            }
        }

        &.sidebar-fixed {
            .main,
            .app-footer {
                margin-left: $sidebar-compact-width;
            }

            .sidebar {
                width: $sidebar-compact-width;
            }

            &.sidebar-hidden {
                .main,
                .app-footer {
                    margin-left: 0;
                }
            }
        }
    }

    .sidebar-minimized {
        .sidebar {
            flex: 0 0 $sidebar-minimized-width;
        }

        &.sidebar-hidden {
            .sidebar {
                margin-left: - $sidebar-minimized-width;
            }
        }

        &.sidebar-fixed {
            .main,
            .app-footer {
                margin-left: $sidebar-minimized-width;
            }

            .sidebar {
                width: $sidebar-minimized-width;
            }

            &.sidebar-hidden {
                .main,
                .app-footer {
                    margin-left: 0;
                }
            }
        }
    }
}

//
// Aside Menu
//
.aside-menu-hidden {
    .aside-menu {
        margin-right: - $aside-menu-width;
    }
}

.aside-menu-fixed {
    .aside-menu {
        position: fixed;
        right: 0;
        height: 100%;

        .tab-content {
            height: calc(
                100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height}
            );
        }
    }

    .main,
    .app-footer {
        margin-right: $aside-menu-width;
    }

    &.aside-menu-hidden {
        .main,
        .app-footer {
            margin-right: 0;
        }
    }
}

.aside-menu-off-canvas {
    .aside-menu {
        position: fixed;
        right: 0;
        z-index: $zindex-sticky - 1;
        height: 100%;

        .tab-content {
            height: calc(
                100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height}
            );
        }
    }
}

//
// Breadcrumb
//
.breadcrumb-fixed {
    .main {
        $breadcrumb-height: 2 * $breadcrumb-padding-y + $font-size-base + 1.5 *
            $spacer;
        padding-top: $breadcrumb-height;
    }

    .breadcrumb {
        position: fixed;
        top: $navbar-height;
        right: 0;
        left: 0;
        z-index: $zindex-sticky - 2;
    }

    // if sidebar + main + aside
    .main:nth-child(2) {
        .breadcrumb {
            right: $aside-menu-width;
            left: $sidebar-width;
        }
    }

    // if sidebar + main
    .main:first-child {
        .breadcrumb {
            right: $aside-menu-width;
            left: 0;
        }
    }

    // if  main + aside
    .main:last-child {
        .breadcrumb {
            right: 0;
        }
    }

    &.sidebar-minimized {
        .main .breadcrumb {
            left: $sidebar-minimized-width;
        }
    }

    &.sidebar-hidden,
    &.sidebar-off-canvas {
        .main .breadcrumb {
            left: 0;
        }
    }

    &.aside-menu-hidden,
    &.aside-menu-off-canvas {
        .main .breadcrumb {
            right: 0;
        }
    }
}

//
// Footer
//
.footer-fixed {
    .app-footer {
        position: fixed;
        bottom: 0;
        z-index: $zindex-sticky;
        width: 100%;
    }

    .app-body {
        margin-bottom: $footer-height;
    }
}

//
// Animations
//
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
    transition: margin-left $layout-transition-speed,
        margin-right $layout-transition-speed, width $layout-transition-speed,
        flex $layout-transition-speed;
}
.breadcrumb {
    transition: left $layout-transition-speed, right $layout-transition-speed,
        width $layout-transition-speed;
}

//
// Mobile layout
//

@include media-breakpoint-down(md) {
    .app-header {
        position: fixed !important;
        z-index: $zindex-sticky;
        width: 100%;
        text-align: center;

        .navbar-toggler {
            @if (lightness( $navbar-brand-bg ) > 40) {
                color: $navbar-color;
            } @else {
                color: #fff;
            }
        }

        .navbar-brand {
            position: absolute;
            left: 50%;
            margin-left: - math.div($navbar-brand-width, 2);
        }
    }

    .app-body {
        margin-top: $navbar-height;
    }

    .sidebar {
        position: fixed;
        width: $mobile-sidebar-width;
        height: 100%;
        margin-left: - $mobile-sidebar-width;

        .sidebar-nav,
        .nav {
            width: $mobile-sidebar-width !important;
        }
    }

    .main,
    .app-footer {
        margin-left: 0 !important;
    }

    // .aside-menu {
    //   margin-right: - $aside-menu-width;
    // }

    .sidebar-hidden {
        .sidebar {
            margin-left: - $mobile-sidebar-width;
        }
    }

    .sidebar-mobile-show {
        .sidebar {
            width: $mobile-sidebar-width;
            margin-left: 0;

            .sidebar-nav {
                height: calc(100vh - #{$navbar-height});
            }
        }

        .main {
            margin-right: - $mobile-sidebar-width !important;
            margin-left: $mobile-sidebar-width !important;
        }
    }

    .breadcrumb-fixed {
        .main .breadcrumb {
            right: 0 !important;
            left: 0 !important;
        }
    }
}
