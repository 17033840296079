// .app-footer {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   padding: 0 $spacer;
//   color: $footer-color;
//   background: $footer-bg;
//   @include borders($footer-borders);
// }
.app-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 $spacer;
    color: $footer-color;
    background: $footer-bg;
    @include borders($footer-borders);
    position: fixed;
    bottom: 0;
    width: calc(100% - 50px);
}