
.container-title {
    font-weight: 500;
    height: 2.5rem;
    display: flex;
    align-items: center;
}
.consultation-title-container {
    background-color: #9b9b9b;
    color: white;
    @extend .container-title;
    @extend .px-2;
}

.consultation-container{
    height:$input-height-sm;
    font-weight: 500;
    display: flex;
    align-items: center;

    &__title{
        //height:$input-height-sm;
      @extend .consultation-container;
      @extend .px-2;
      background-color: #9b9b9b;
      color: white;
      width:100%;
    }

    &__title-duck-egg-blue{
      background-color: $duck-egg-blue;
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 5px;
      padding-right: 5px;
      @extend .consultation-container;
      @extend .black;
    }
}

.container-duck-egg-blue-two {
    background-color: $duck-egg-blue-two;
}

.consultation-title-container-duck-egg-blue {
    background-color: $duck-egg-blue;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 5px;
    padding-right: 5px;
    @extend .container-title;
    @extend .black;
}

.black {
    color: $black;
}

// Customization
accordion-group .panel-heading {
    padding: 0;
}

accordion-group .panel-body {
    @extend .pt-0;
}

accordion-group .panel.card.panel-default {
    border: none;
    margin-bottom: 0;
    margin-left: -15px;
    margin-right: -15px;
}

.icon-size {
    font-size: 0.6rem;
    align-items: 'center';
    justify-content: 'center';
}

.consultation-history-br {
    border-top-color: $warm-grey-two;
    margin-left: -10px;
    margin-right: -10px;
}

.more-down-arrow {
    width: 0.9375rem;
    height: 0.9375rem;
    border: solid 1px $silver;
}
