// Calendar Month View
.cal-month-view .cal-cell {
    float: left;
    flex: 1;
    -js-display: flex;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    color: #000000;
    text-transform: uppercase;
    font-weight: 400;
}

.cal-open-day-events-custom {
    background-color: #f2f2f2;
}

.cal-month-view .cal-open-day-events {
    background-color: #ededed;
    color: $gray-950;
    box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.5);
    font-size: $font-size-base;
}

.cal-month-view .cal-day-number {
    opacity: 1;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.5;
    cursor: default; 
}

// Calendar Week View
.cal-week-view .cal-day-headers .cal-header {
    flex: 1;
    text-align: center;
    padding: 5px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 400;
}

.cal-week-view .cal-time {
    height: 30px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 400;
}

.cal-week-view .cal-event {
    border: 1px solid #cdcdcd;
    font-size: $font-size-base;
    word-wrap: break-word;
    white-space: normal;
}

.cal-week-view .cal-time-events .cal-event-container {
    right: 10%;
    width: auto!important;
}

.cal-week-view-custom {
    background-color: #f9f9fa!important;
    border-radius: 0px!important;
    border-top-width: 5px!important;
}

.cal-week-view-custom-external {
    color: rgba(0, 0, 0, 0.5)!important;
    background-color: #f4d0d0!important;
    border-radius: 0px!important;
    width: 80%;
}

.cal-week-view-public-holiday {
    color: #4a4a4a!important;
    background-color: white!important;
    background: repeating-linear-gradient(-55deg, #ffffff, #eaeaea 4px);
    border-radius: 0px!important;
    width: 80%!important;
}

.cal-week-view-doctor-leave {
    color: #4a4a4a!important;
    background-color: white!important;
    background: repeating-linear-gradient(-55deg, #ffffff, #eaeaea 4px);
    border-radius: 0px!important;
    width: 50%!important;
}


.cal-week-view-blocked-time {
    color: #4a4a4a!important;
    background-color: white!important;
    border-radius: 0px!important;
    background: repeating-linear-gradient(-55deg, #ffffff, #eaeaea 4px);
    width: 50%;
}

.cal-week-event-popover-custom {
    background-color: $white;
    width: 18rem!important;
    max-width: 30rem !important
}

.cal-week-event-popover-custom .popover-content.popover-body {
    max-width: 20rem !important;
    padding: 0px;
}

.cal-week-view .cal-time-events .cal-day-columns {
    height: fit-content;
}

.cal-week-view .cal-time-events {
    max-height: -webkit-fill-available;
    padding-top: 52px;
}

// .cal-week-view .cal-hour .cal-hour-segment {
// border-bottom: red solid;
// }
// Calendar Day View
.cal-day-view .cal-event {
    border: 1px solid #cdcdcd;
    font-size: $font-size-base;
    word-wrap: break-word;
    white-space: normal;
}

.cal-day-view-public-holiday {
    color: #4a4a4a!important;
    background-color: white!important;
    background: repeating-linear-gradient(-55deg, #ffffff, #eaeaea 4px);
    border-radius: 0px!important;
    width: 50%;
}

.cal-day-view-custom {
    background-color: #f9f9fa!important;
    border-radius: 0px!important;
    border-top-width: 7px!important;
}

.cal-day-view .cal-drag-active {
    margin-left: 0!important;
}

.cal-day-view .cal-event-container.cal-drag-active {
    margin-left: 3.5%!important;
    width: 84.5%!important;
}

.cal-day-view .cal-hour-rows {
    height: 100vh;
    overflow-y: scroll;
}

// Cal Hour Segment
.cal-hour-segment-off-hours {
    background-color: #e8e3e3;
}

// Appointments
.navbar-nav li>a {
    text-transform: capitalize;
    color: #333;
    transition: background-color .2s, color .2s;
    &:hover,
    &:focus {
        background-color: #333;
        color: #fff;
    }
}

.navbar-nav li.active>a {
    background-color: #333;
    color: #fff;
}

ul.nav.nav-pills {
    justify-content: center;
    padding-top: 2em;
}

.confirmation div.tab-content {
    border: none;
}

tabset.nav-hidden .nav-item {
    display: none;
}

input.form-control.text-center.bs-timepicker-field {
    height: 1.7rem;
    margin-top: -1px;
}

// Navigation Bar
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    // top: 0;
    right: 0;
    background-color: #eeeeee;
    box-shadow: 2px -2px 20px 0px #808080b8;
    overflow-x: hidden;
    transition: 0.2s;
    margin-top: 2rem;
    // text-align: left;
    // padding: 1rem 1rem;
}

.sidenav-header {
    height: 5%;
    width: 20;
    position: fixed;
    z-index: 2;
    // top: 0;
    right: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.2s;
    // padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.2s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .filter {
    padding: 1rem 1rem;
    text-align: left;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}

.timepicker-report tr {
    vertical-align: initial!important;
}

.timepicker-report button {
    padding: 0;
}

.appt-detail {
    background-color: rgba(198, 203, 212, 0.1);
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.off-hours {
    // background-color: #e1e1e1;
    background: rgba(88, 88, 88, 0.308);
    pointer-events: none;
    // z-index: 1;
}

.currentTime {
    // border-bottom: red 1px solid!important;
    // bottom: 10%;s
    // z-index: 1;
}

.redLine {
    border-bottom: 1.5px red solid !important;
    // z-index: 1;
}

@mixin font-size-bigger() {
    font-size: calc(#{$font-size-base} * 1.3);
}

.ok-tick {
    @include font-size-bigger();
}