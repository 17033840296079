@font-face {
  font-family: 'fontello';
  src: url('./font/fontello.eot?28581654');
  src: url('./font/fontello.eot?28581654#iefix') format('embedded-opentype'),
       url('./font/fontello.woff2?28581654') format('woff2'),
       url('./font/fontello.woff?28581654') format('woff'),
       url('./font/fontello.ttf?28581654') format('truetype'),
       url('./font/fontello.svg?28581654#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?28581654#fontello') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-bell:before { content: '\e800'; } /* '' */
.icon-print:before { content: '\e801'; } /* '' */
.icon-patients:before { content: '\e802'; } /* '' */
.icon-location:before { content: '\e803'; } /* '' */
.icon-communication:before { content: '\e804'; } /* '' */
.icon-queue:before { content: '\e805'; } /* '' */
.icon-expense:before { content: '\e806'; } /* '' */
.icon-settings:before { content: '\e807'; } /* '' */
.icon-plus-1:before { content: '\e808'; } /* '' */
.icon-minus:before { content: '\e809'; } /* '' */
.icon-pencil:before { content: '\e80a'; } /* '' */
.icon-search:before { content: '\e80b'; } /* '' */
.icon-reposts:before { content: '\e80c'; } /* '' */
.icon-products:before { content: '\e80d'; } /* '' */
.icon-trash-empty:before { content: '\e80e'; } /* '' */
.icon-info-circled:before { content: '\e80f'; } /* '' */
.icon-up-open:before { content: '\e810'; } /* '' */
.icon-down-open:before { content: '\e811'; } /* '' */
.icon-left-open:before { content: '\e812'; } /* '' */
.icon-right-open:before { content: '\e813'; } /* '' */
.icon-calendar:before { content: '\e814'; } /* '' */
.icon-heart-empty:before { content: '\e815'; } /* '' */
.icon-heart:before { content: '\e816'; } /* '' */
.icon-clipboard:before { content: '\e817'; } /* '' */
.icon-clipboard-1:before { content: '\e818'; } /* '' */
.icon-down-open-big:before { content: '\e819'; } /* '' */
.icon-left-open-big:before { content: '\e81a'; } /* '' */
.icon-right-open-big:before { content: '\e81b'; } /* '' */
.icon-up-open-big:before { content: '\e81c'; } /* '' */
.icon-down-dir:before { content: '\e81d'; } /* '' */
.icon-left-dir:before { content: '\e81e'; } /* '' */
.icon-right-dir:before { content: '\e81f'; } /* '' */
.icon-up-dir:before { content: '\e820'; } /* '' */
.icon-cancel:before { content: '\e821'; } /* '' */
.icon-attention:before { content: '\e822'; } /* '' */
.icon-attention-alt:before { content: '\e823'; } /* '' */
.icon-cancel-1:before { content: '\e824'; } /* '' */
.icon-export-3:before { content: '\e825'; } /* '' */
.icon-right-circle:before { content: '\e826'; } /* '' */
.icon-left-circle:before { content: '\e827'; } /* '' */
.icon-flash:before { content: '\e828'; } /* '' */
.icon-lock:before { content: '\e829'; } /* '' */
.icon-lock-1:before { content: '\e82a'; } /* '' */
.icon-keylock:before { content: '\e82b'; } /* '' */
.icon-up-big:before { content: '\e82c'; } /* '' */
.icon-down-big:before { content: '\e82d'; } /* '' */
.icon-up:before { content: '\e82e'; } /* '' */
.icon-down:before { content: '\e82f'; } /* '' */
.icon-user-delete:before { content: '\e830'; } /* '' */
.icon-font:before { content: '\e831'; } /* '' */
.icon-action-undo:before { content: '\e832'; } /* '' */
.icon-action-redo:before { content: '\e833'; } /* '' */
.icon-floppy:before { content: '\e834'; } /* '' */
.icon-download:before { content: '\e835'; } /* '' */
.icon-leaf:before { content: '\e836'; } /* '' */
.icon-block:before { content: '\e837'; } /* '' */
.icon-user:before { content: '\e838'; } /* '' */
.icon-at:before { content: '\e839'; } /* '' */
.icon-phone:before { content: '\e83a'; } /* '' */
.icon-ok:before { content: '\e83b'; } /* '' */
.icon-picture:before { content: '\e83c'; } /* '' */
.icon-lock-open:before { content: '\e83d'; } /* '' */
.icon-link:before { content: '\e83e'; } /* '' */
.icon-warning-empty:before { content: '\e83f'; } /* '' */
.icon-warehouse:before { content: '\e840'; } /* '' */
.icon-warning:before { content: '\e841'; } /* '' */
.icon-attention-1:before { content: '\e842'; } /* '' */
.icon-attention-2:before { content: '\e843'; } /* '' */
.icon-plus-squared:before { content: '\e844'; } /* '' */
.icon-minus-squared:before { content: '\e845'; } /* '' */
.icon-left-circled:before { content: '\f0a8'; } /* '' */
.icon-right-circled:before { content: '\f0a9'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-full-screen:before { content: '\f0b2'; } /* '' */
.icon-user-md:before { content: '\f0f0'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-eraser:before { content: '\f12d'; } /* '' */
.icon-ellipsis:before { content: '\f141'; } /* '' */
.icon-ellipsis-vert:before { content: '\f142'; } /* '' */
.icon-history:before { content: '\f1da'; } /* '' */
.icon-binoculars:before { content: '\f1e5'; } /* '' */
.icon-trash:before { content: '\f1f8'; } /* '' */
.icon-brush:before { content: '\f1fc'; } /* '' */
.icon-appointments:before { content: '\f271'; } /* '' */
.icon-calendar-times-o:before { content: '\f273'; } /* '' */
.icon-invoice:before { content: '\f298'; } /* '' */
.icon-contact:before { content: '\f2ba'; } /* '' */
.icon-address-card-o:before { content: '\f2bc'; } /* '' */
