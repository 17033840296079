// CUSTOM CHECKBOX
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border: 1px solid;
}


/* On mouse-over, add a grey background color */

.container:hover input~.checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */


/* The container */

.checkmark-container {
    display: block;
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #4a4a4a;
}


/* Hide the browser's default checkbox */

.checkmark-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border: 1px solid #cccacd;
    margin-top: 0.18rem;
    // display: inline-block;
}


/* On mouse-over, add a grey background color */

.checkmark-container:hover input~.checkmark {
    background-color: white;
    border: 1px solid #2196f3;
}


/* When the checkbox is checked, add a blue background */

.checkmark-container input:checked~.checkmark {
    background-color: white;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.checkmark-container input:checked~.checkmark:after {
    display: block;
}

.checkmark-container input:disabled~.checkmark {
    background-color: #c2cfd6;
    border: 1px solid #AEBAC0;
}


/* Style the checkmark/indicator */

.checkmark-container .checkmark:after {
    left: 0.3rem;
    // top: 0.1rem;
    width: 0.3rem;
    height: 0.7rem;
    border: solid#2196f3;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

// CUSTOM CHECKBOX
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
  }

  // Box hover
  &:hover + label:before {
    background: #f35429;
  }
  
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: #f35429;
  }
  
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  // &:checked + label:after {
  //   content: '';
  //   position: absolute;
  //   left: 5px;
  //   top: 9px;
  //   background: white;
  //   width: 2px;
  //   height: 2px;
  //   box-shadow: 
  //     2px 0 0 white,
  //     4px 0 0 white,
  //     4px -2px 0 white,
  //     4px -4px 0 white,
  //     4px -6px 0 white,
  //     4px -8px 0 white;
  //   transform: rotate(45deg);
  // }
}