.scrollable-card {
  &.card {
    max-height: 31.25rem;
  }

  >.card-body {
    overflow-y: scroll;
    max-height: 29.9375rem;
  }

  // >.card-body::-webkit-scrollbar {
  //   width: 0 !important;
  // }
}
