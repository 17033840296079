.grey-header {
    background-color: #9b9b9b;
    color: white;
    height: 40px;
    align-items: center;
}

.balance-positive {
    font-weight: 500;
    color: $balance-green;
}

.balance-unknown {
    font-weight: 500;
    color: $peacock-blue
}

.balance-negative {
    font-weight: 500;
    color: $maroon;
}