// core overrides
$sidebar-minimizer-indicator-color: $white !default;

// OUE Custom
// $indigo-100: #0093dc !default;
// $pink-100: #e64a79 !default;
// $grey-100: #f8f8f8 !default;
// OUE Custom

// SIDEBAR
// $sidebar-bg: $indigo-100 !default;
$sidebar-nav-link-icon-color: #fff !default;
$sidebar-nav-link-active-icon-color: #fff !default;
// $sidebar-nav-dropdown-bg: #555194 !default;
// $navbar-active-color:#555194 !default;
// $navbar-hover-color:#cfcddf !default;

// $sidebar-nav-link-active-bg:#555194 !default;
// $sidebar-nav-link-hover-bg:$white !default;

$sidebar-nav-dropdown-indicator-color: $white !default;

$navbar-bg: $grey-100;
// $navbar-brand-width: 200px !default;

$sidebar-width: 185px !default;
$navbar-brand-width: 185px !default;
// Button
// $button-pink: $pink-100 !default;

// $input-border-radius: 0.3rem;
