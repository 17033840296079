body {
    position: relative;
}

$select-height: $input-height-sm;
ng-select.custom-select {
    @extend .p-0;
    // height: calc($input-height-sm+1) !important;
    // padding: 0 !important;
    height: $select-height !important;
    height: auto;
}

ng-select.custom-select .ng-select-container {
    min-height: 0px;
    height: calc(#{$select-height} - 2px) !important;
    overflow: visible;
    // height: calc(1.43rem + 2px) !important;
}

// ng-select.custom-select ng-dropdown-panel{
//   height: auto;
//   min-width: 100%;
//   width: intrinsic !important;           __<<ngThemingMigrationEscapedComment0>>__
//   width: -moz-max-content !important;    __<<ngThemingMigrationEscapedComment1>>__
//   width: -webkit-max-content !important; __<<ngThemingMigrationEscapedComment2>>__
// }
.appointmentsSelect .ng-select .ng-select-container {
    border: display;
}

.ng-select .ng-select-container {
    border: none;
    border-radius: 0px;
}

ng-select.ng-select-multiple.custom-select-multiple {
    .ng-select-container {
        min-height: initial !important;
        border: 1px solid #c2cfd6;
        .ng-value-container {
            padding-top: 1px;
            .ng-value {
                margin-bottom: 1px;
            }
            .ng-value+.ng-input {
                width: 0px;
            }
        }
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    background-color:white;
}

ng-select.custom-select-multiple {
    height: auto;
    .ng-select-container {
        height: inherit;
    }
}

.ng-select.ng-select-disabled>.ng-select-container {
    background-color: #f4f4f4;
}

.ng-dropdown-panel-items.scrollhost .scrollable-content .ng-option {
    white-space: normal!important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal!important;
}

.custom-select-small-width>.ng-dropdown-panel {
    height: auto;
    min-width: 100%;
    width: 7vw !important;
    // width: intrinsic !important;           __<<ngThemingMigrationEscapedComment3>>__
    // width: -moz-fit-content !important;    __<<ngThemingMigrationEscapedComment4>>__
    // width: -webkit-fit-content !important; __<<ngThemingMigrationEscapedComment5>>__
}

.custom-select-extra-medium-width>.ng-dropdown-panel {
    height: auto;
    min-width: 100%;
    width: 15vw !important;
    // width: intrinsic !important;           __<<ngThemingMigrationEscapedComment6>>__
    // width: -moz-fit-content !important;    __<<ngThemingMigrationEscapedComment7>>__
    // width: -webkit-fit-content !important; __<<ngThemingMigrationEscapedComment8>>__
}

.custom-select-extra-extra-medium-width>.ng-dropdown-panel {
    height: auto;
    min-width: 100%;
    width: 22vw !important;
    // width: intrinsic !important;           __<<ngThemingMigrationEscapedComment9>>__
    // width: -moz-fit-content !important;    __<<ngThemingMigrationEscapedComment10>>__
    // width: -webkit-fit-content !important; __<<ngThemingMigrationEscapedComment11>>__
}

.custom-select-almost-medium-width>.ng-dropdown-panel {
    height: auto;
    min-width: 100%;
    width: 27vw !important;
    // width: intrinsic !important;           __<<ngThemingMigrationEscapedComment12>>__
    // width: -moz-fit-content !important;    __<<ngThemingMigrationEscapedComment13>>__
    // width: -webkit-fit-content !important; __<<ngThemingMigrationEscapedComment14>>__
}

.custom-select-medium-width>.ng-dropdown-panel {
    height: auto;
    min-width: 100%;
    width: 35vw !important;
     white-space: normal;
    // width: intrinsic !important;           __<<ngThemingMigrationEscapedComment15>>__
    // width: -moz-fit-content !important;    __<<ngThemingMigrationEscapedComment16>>__
    // width: -webkit-fit-content !important; __<<ngThemingMigrationEscapedComment17>>__
}

.custom-select-extra-width>.ng-dropdown-panel {
    height: auto;
    min-width: 100%;
    width: 45vw !important;
     white-space: normal;
    // width: intrinsic !important;           __<<ngThemingMigrationEscapedComment18>>__
    // width: -moz-fit-content !important;    __<<ngThemingMigrationEscapedComment19>>__
    // width: -webkit-fit-content !important; __<<ngThemingMigrationEscapedComment20>>__
}

ng-dropdown-panel.custom-select-extra-width {
    height: auto;
    min-width: 100%;
    width: 45vw !important;
     white-space: normal;
    // width: intrinsic !important;           __<<ngThemingMigrationEscapedComment21>>__
    // width: -moz-fit-content !important;    __<<ngThemingMigrationEscapedComment22>>__
    // width: -webkit-fit-content !important; __<<ngThemingMigrationEscapedComment23>>__
}

.custom-select-extra-large-width>.ng-dropdown-panel {
    height: auto;
    min-width: 100%;
    width: 60vw !important;
}
.inventory-count-reason.ng-dropdown-panel{
    left: calc(100% - 24%) !important;
}

.dropdown-right>.ng-dropdown-panel {
    right: 0;
    left: -5.3rem !important; // Set to 1 so that it will be overriden
}

.instr-dropdown-right>.ng-dropdown-panel {
    right: 0;
    left: -53rem !important; // Set to 1 so that it will be overriden
}

.prescr-dropdown-right>.ng-dropdown-panel {
    right: 0;
    left: -8.5rem !important; // Set to 1 so that it will be overriden
}

// FOR APPEND TO BODY
.custom-select-extra-large-width.ng-dropdown-panel {
    height: auto;
    min-width: 100%;
    width: 60vw !important;
}

.custom-select-almost-medium-width.ng-dropdown-panel {
    height: auto;
    min-width: 100%;
    width: 27vw !important;
    // width: intrinsic !important;           __<<ngThemingMigrationEscapedComment24>>__
    // width: -moz-fit-content !important;    __<<ngThemingMigrationEscapedComment25>>__
    // width: -webkit-fit-content !important; __<<ngThemingMigrationEscapedComment26>>__
}

.custom-select-extra-extra-medium-width.ng-dropdown-panel {
    height: auto;
    min-width: 100%;
    width: 22vw !important;
    // width: intrinsic !important;           __<<ngThemingMigrationEscapedComment27>>__
    // width: -moz-fit-content !important;    __<<ngThemingMigrationEscapedComment28>>__
    // width: -webkit-fit-content !important; __<<ngThemingMigrationEscapedComment29>>__
}

.custom-select-almost-medium-width.ng-dropdown-panel.instr-dropdown-right {
    // left:59.5rem!important;
    // width: intrinsic !important;           __<<ngThemingMigrationEscapedComment30>>__
    // width: -moz-fit-content !important;    __<<ngThemingMigrationEscapedComment31>>__
    // width: -webkit-fit-content !important; __<<ngThemingMigrationEscapedComment32>>__
    left: auto!important;
    right: 7rem!important
}