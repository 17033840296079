.axis path, .axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.growthChartline {
  fill: none;
  stroke: gray;
  stroke-width: 1.5px;
}

.line-blue {
  fill: none;
  stroke: #263996;
  stroke-width: 1.5px;
}

.line-pink {
  fill: none;
  stroke: #CD74AF;
  stroke-width: 1.5px;
}

.line-yellow {
  fill: none;
  stroke: #F4ED3B;
  stroke-width: 1.5px;
}

.line-light-blue {
  fill: none;
  stroke: #3897a8;
  stroke-width: 1.5px;
}

.line-purple {
  fill: none;
  stroke: #AB4E9F;
  stroke-width: 1.5px;
}

.line-brown {
  fill: none;
  stroke: #A92521;
  stroke-width: 1.5px;
}

.line-green {
  fill: none;
  stroke: #0FA0A2;
  stroke-width: 1.5px;
}

.line-orange {
  fill: none;
  stroke: #F7A7A2;
  stroke-width: 1.5px;
}

.line-cream {
  fill: none;
  stroke: #FFDDB9;
  stroke-width: 1.5px;
}

.line-light-grey {
  fill: none;
  stroke: #B9BADC;
  stroke-width: 1.5px;
}

.pLine {
  fill: none;
  stroke: black;
  stroke-width: 1.5px;
}

.areaBoy {
  fill: steelblue;
  opacity: 0.2;
}

.areaGirl {
    fill: lightpink;
    opacity: 0.2;
}

.dot {
  fill: black;
  stroke: black;
  stroke-width: 1.5px;
}

.dotSelected {
  fill: rgb(237,126,30);
  stroke: rgb(237,126,30);
  stroke-width: 1.5px;
}

.rect-to-axis {
  /*fill: rgba(0,0,0,.05);*/
  fill: rgba(0,0,0,0);
  stroke: rgba(20,20,20, .8);
}

.backgroundRect {
  fill: rgba(255,255,255,1);
  stroke: rgba(20,20,20, .8);
}

.axis path,
.axis line {
    fill: none;
    stroke: black;
    shape-rendering: crispEdges;
}

text {
    font-family: sans-serif;
    font-size: 11px;
}

.tooltipTextBackground {
  fill:rgba(237,126,30,0.5);
  /*stroke: rgba(20,20,20, .8);*/
}
