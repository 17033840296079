.blocked-time{
    color: maroon;
    font-size: 20px;
}

.info-circle{
    color:grey;
    font-size: 17px;
    cursor: pointer;
}

.font-20{
    font-size: 20px;
}