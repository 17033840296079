// @use 'bootstrap-variables'as bootVariables;

#queue-div .queue-header {
  background-color: white;
  height: $tab-height;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #dddee0;
  margin: 0;
}

#queue-pane {
  width: auto;
  border-right-color: rgb(194, 207, 214);
  border-right-style: solid;
  border-right-width: 1px;
  background-color: #ffffff;
  height: 100vh !important;
}

// #queue-pane.collapsed {
//     width: 50px;
// }
.left-box-shadow {
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.bottom-box-shadow {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.item-even {
  background-color: $white;
  padding: 0;
}

.item-odd {
  background-color: #f1f3f7;
  padding: 0;
}

app-queue-item li.list-group-item {
  border-right-style: none;
  border-top-style: none;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.text-small {
  font-size: 10px;
}

.text-x-small {
  font-size: 9px;
}

.text-xx-small {
  font-size: 80%;
}

ul.list-group.list-group-item {
  margin: 0;
}

.align-items-center {
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: -webkit-box !important;
  display: flex !important;
}

li.nav-item.hiddenTab {
  display: none;
}

.queue-row {
  // border-style: solid;
  // border-color: white;
  // border-width: 1px;
  display: table;
  width: 100%;
  margin: 0 auto;
  min-height: 45px;
  cursor: pointer;
}

div.row.border.alertsPanel {
  border-color: #dddee0 !important;
}

.queue-list {
  // padding-top: 41px;
  max-height: calc(100vh - 265px);
  margin-bottom: 10px;
  overflow-y: auto;
}

.queue-number {
  display: table-cell;
  width: 15%;
  // vertical-align: middle;
  padding-top: 1em;
}

.queue-row table {
  width: 100%;
  min-height: 50px;
}

.queue-row td {
  width: 50%;
}

.queue-call-button {
  width: 15%;
  position: absolute;
  bottom: 0;
}

.no-show-button {
  width: 15%;
  position: absolute;
  bottom: 44px;
}

.queue-consult-button {
  width: 15%;
  position: absolute;
  bottom: 22px;
}

.queue-first-element {
  padding-top: 5px;
  padding-bottom: 0px;
}

.queue-last-element {
  padding-top: 0px;
  padding-bottom: 5px;
}

.queue-details {
  width: 100%;
  overflow-x: hidden;
  /* margin: auto; */
}

// Header
.app-header-data-title {
  color: $silver;
  font-size: $font-size-base;
}

.app-header-data-info {
  color: $white;
  font-weight: 500;
  font-size: $font-size-base;
}

.navbar-logo {
  border-bottom-style: none;
  height: 80px;
}

.app-header.navbar .navbar-brand {
  height: $navbar-height;
  border-bottom: none;
}

.brand-minimized .app-header.navbar .navbar-brand {
  border-bottom: none;
}

// Card
.card-header-transparent {
  background-color: transparent !important;
  padding: 0.2rem 0.5rem;
}

.card-body-diagnosis {
  padding: 0.2rem 0.5rem;
}

// Nav-link
tabset.consultation .nav-link.active {
  color: $indigo-100;
  background-color: transparent;
  border-bottom-color: theme-color('brand-secondary');
  border-bottom-style: solid;
  border-radius: 0;
}

tabset.consultation .nav-pills .nav-link {
  color: gray;
  font-weight: 500;
  padding: 0.9em;
  padding-top: 0.9em;
  padding-right: 2em;
  padding-bottom: 0.9em;
  padding-left: 0.9em;
}

tabset.consultation .nav-pills .nav-link.disabled {
  color: $gray-300;
}

.printing-disabled {
  cursor: help;
  display: inline;
  position: relative;
}

.printing-disabled:hover:after {
  content: "Please check tabs and fill/correct any \A information highlighted in red to enable Printing Tab.";
  display: block;
  white-space: pre;
  position: relative;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  width: auto;
  padding: 8px;
  font-size: 0.75rem;
  color: black;
  background-color: white;
  z-index: 1;
}

.services-disabled {
  cursor: help;
  display: inline;
  position: relative;
}

.services-disabled:hover:after {
  content: "This is not allowed";
  display: block;
  white-space: pre;
  position: relative;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  width: auto;
  padding: 8px;
  font-size: 0.75rem;
  color: black;
  background-color: white;
  z-index: 1;
}

.pcn-disabled {
  cursor: help;
  display: inline;
  position: relative;
}

.pcn-disabled:hover:after {
  content: "This is not allowed";
  display: block;
  white-space: pre;
  position: relative;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  width: auto;
  padding: 8px;
  font-size: 0.75rem;
  color: black;
  background-color: white;
  z-index: 1;
}


tabset.consultation {
  padding-left: 0px;
}

tabset.consultation.tab-container {
  width: 100%;
}

tabset.consultation div.tab-content .tab-pane {
  // padding: 1rem 1rem 1rem 0.5rem;
  // padding: 0.5rem 0.5rem;
  //padding: 0.5rem 0.8rem 0.5rem 0.5rem !important;
  padding: 2px !important;
}

#consultation-select .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #00aced;
  font-style: italic;
}

.consultationPane {
  // padding: 1rem 1rem 1rem 0.5rem;
  // padding: 0.5rem 0.5rem;
  padding: 0.6rem 0.5rem 0.5rem 0.5rem !important;
}

.list-group-item.selected {
  background-color: #d2d2d2;
}

tabset.consultation ul.nav.nav-pills {
  background-color: white;
  // position: fixed;
  width: auto;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  padding-top: 0px;
  border-width: 1px;
  border-bottom-style: solid;
  border-color: #dddee0;
  z-index: 10;
  height: calc($tab-height+0.625rem);

  // make overflow item horizontal scrollable
  /* flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  li {
    display: inline-block;
    padding-bottom: 5px;
  } */
}

tabset.consultation ul.nav.nav-pills::-webkit-scrollbar:horizontal {
  height: 2px;
}

tabset.consultation div.tab-content {
  // background-color: $gray-lighter;
  // border-style: none;
  // margin-top: 0px;
  // max-height: calc(100vh - 180px);
  // max-height: 100vh;
  // margin-bottom: 10px;
  // padding-bottom: 100px;
  // padding-bottom: 8rem;
  // overflow-y: auto;
  background-color: #f2f2f2;
  border-style: none;
  margin-top: 0px;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
}

.tabcontainer__padding-bottom {
  padding-bottom: 3rem;
}

.edit-detail-btn {
  position: absolute;
  z-index: 20;
  color: white;
  width: 16%;
  right: 1rem;
  top: 0.25rem;
}

.alertsPanel {
  height: 48px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 3;
  background-color: white;
}

//Consultation-Pane
#consultation-pane {
  // max-height: 100vh;
  margin-bottom: 50em;
  overflow-y: auto !important;
}

// .sidebar.sidebar-brand-main a.nav-link.active {
//   background-color: $barney-purple !important;
// }

// .sidebar.sidebar-brand-main a.nav-link:hover {
//   background-color: $barney-purple !important;
// }

.sub-heading-grey {
  background-color: #f2f2f2;
  color: black;
  font-size: 10px;
  padding: 0.25em;
  text-align: center;
}

// Table display
.hideOverflow {
  overflow: hidden;
}

#queue-pane,
#consultation-pane {
  display: table-cell;
}

.charges-fixed-bottom {
  background-color: #e3f2fa;
  position: fixed;
  bottom: 17px;
  width: 100%;
}

// .main-visit-history {
//     // max-height: 23.1rem;
//     // overflow:scroll;
// }
.visit-history-list-container {
  max-height: 23.1rem;
  overflow: scroll;
}

.visit-history-list-ngx-datable {
  .page-count {
    display: none;
  }

  .datatable-footer {
    .datatable-pager {
      margin: 0px !important;
      height: 100%;
    }
  }
}

.visit-history-list-container-left {
  max-height: 16.25rem;
  overflow: scroll;
}

.visit-history {
  overflow-y: scroll;
  margin-bottom: 4px;

  .list-group-item.active {
    background-color: white !important;
  }

  .list-group-item {
    @extend .text-dark;
    @extend .bg-pale-grey-three;
    border-bottom-color: #d7dae2;
    margin-bottom: 0px;
  }
}

.case-header {
  position: fixed;
  top: 3.125rem;
  height: 3.4375rem;
  width: 100%;
  z-index: 99;
}

.content-after-header {
  margin-top: 3.4375rem;
  min-height: 100%;
}

.case-content {
  @extend .content-after-header;
  // margin-top: 3.4375rem;
  overflow-y: scroll;
  // min-height: 100%;
}

// Problem List
.problem-list-badge {
  text-align: center;
  font-size: $font-size-base;

  &.inactive {
    background-color: #e3f2fa;
  }

  &.long-term {
    background-color: #ffb56a;
  }

  &.short-term {
    background-color: #98ffdb;
  }
  &.NOTE {
    background-color: #e295e5;
  }

  &.date {
    background-color: #d7dae2;
  }
}

// Font Size
@mixin header-info-font-reduce-by($pixel) {
  .app-header-data-title {
    font-size: calc(#{$font-size-base} - #{$pixel});
  }

  .app-header-data-info {
    font-size: calc(#{$font-size-base} - #{$pixel});
  }
}
