// @import '~@swimlane/ngx-datatable/release/index.css';
// @import '~@swimlane/ngx-datatable/release/themes/material.css';
// @import '~@swimlane/ngx-datatable/release/themes/bootstrap.css';
// @import '~@swimlane/ngx-datatable/release/assets/icons.css';
// @import '~@ng-select/ng-select/themes/default.theme.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');

@mixin bootstrap-button($background) {
  $color: $background;
  $border: 5%;

  @if (lightness($background)>=lightness(#aaa)) {
    $color: #333;
    $border: .2 * lightness($background);
  }

  @include button-variant($color,
    $background,
    darken($background, $border));
}

.btn-general {
  @include bootstrap-button($barney-purple);
}

.btn-circle {
  border-radius: 50% !important;
  padding: 0.25rem;
}

// POPOVER BUTTON
#popoverbtn.btn {
  -webkit-transition: none;
  transition: none;
}

#popoverbtn.btn:focus,
.btn.focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.popoverPrice {
  background-color: #4a4a4a;
}

.popoverPrice .popover>.arrow:after {
  border-top-color: #4a4a4a;
}

.popoverPrice .popover {
  background-color: #4a4a4a;
}

.popoverPrice .popover-content.popover-body {
  max-width: 30rem !important;
  width: 20rem !important;
  color: white;
  padding: 0.1rem 0.3rem;
}

// FILTER BUTTON
#filter-btn {
  max-width: 3.7em;
}

#filter-btn.collapsed {
  max-width: none;
}

// CLINIC BUTTON
.clinicBtn {
  // border-style: blue;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.3rem;
  background-color: white;
}

.clinicBtn:hover {
  -webkit-box-shadow: 0 0 10px 0 #02bbf1;
  box-shadow: 0 0 10px 0 #02bbf1;
}

// TO BE SORTED
.btn-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

// BUTTON CUSTOMIZATION
.btn-mini {
  width: 1rem;
}

.btn-action {
  width: 1.7rem;
  height: 1.7rem;
}

.btn-x-small {
  min-width: 6rem;
}

.btn-small {
  min-width: 8rem;
}

.btn-medium {
  min-width: 10rem;
}

.btn-large {
  min-width: 11.56rem;
}

.btn-print {
  width: 11.1rem;
}

.btn-print-dropdown {
  width: 9.4rem;
}

.btn-turqoise {
  border-radius: 0.8em;
  background-color: #238a9f;
  color: white;
}

.btn-violet {
  border-radius: 0.8em;
  background-color: #454079;
  color: white;
  padding: 0.5em 2em;
}

.btn-pill {
  border-radius: 50em;
}

.btn-pill-violet.pill {
  background-color: unset;
  padding-top: 15px;
  margin-left: 30px;
  color: black;
}

.btn-pill-violet.pill.active {
  width: 60px;
  height: 30px;
  background-color: #555194;
  color: white;
  padding-top: 15px;
}

.btn-link-grey {
  @extend .btn-link;
  color: $grey-800;
}

.btn-link-peacock-blue {
  @extend .btn-link;
  color: $peacock-blue;
}

.btn-transparent {
  text-decoration: none;
}

.btn-label {
  position: relative;
  margin-right: 0.125rem; // display: inline-block;
  // padding: 3px 6px;
  background: $gray-950;
  border-radius: 4px 0 0 4px;
}

.menu-button {
  font-size: $font-size-base; // padding: 0 0 0 2rem;
  background-color: transparent;
  border: none;
  float: right; // background-color: lightgray;
}

.menu-button-white {
  font-size: $font-size-base; // padding: 0 0 0 2rem;
}

.badge-button-alignment {
  padding-top: 0.5em;
}

// PHONE PREFIX DISABLED BUTTON
int-phone-prefix button:disabled {
  background-color: #f5f6f5 !important;
  opacity: 1 !important;
  max-height: 29px;
}

.btn-sm-sub {
  @include button-size($btn-padding-y-sm-sub,
    $btn-padding-x-sm-sub,
    $font-size-sm-sub,
    $btn-line-height-sm-sub,
    $btn-border-radius-sm-sub);
  white-space: nowrap;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
