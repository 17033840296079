// MODAL : START
.custom-modal {
  .modal-header {
    color: #fff;
    background-color: #555194;
    border-bottom: 0;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 15px;

    .modal-title {
      font-size: inherit;
    }

    .close {
      span {
        color: #FFF;
        font-size: 20px;
      }
    }
  }
}
// MODAL : END

// BUTTONS : START
.button-primary {
  background-color: #555194;
  border-radius: 4px;
  border: 2px solid #555194;
  color: #FFF;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.button-primary:disabled {
  background-color: #918EBA;
  border-radius: 4px;
  border: 2px solid #918EBA;
  color: #FFF;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: not-allowed
}

.button-secondary {
  background-color: #5551944a;
  border-radius: 4px;
  border: 2px solid #55519400;
  color: #555194;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.button-green {
  background-color: #238A9F;
  border-radius: 4px;
  border: 2px solid #238A9F;
  color: #FFF;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.button-green:disabled {
  background-color: #71B3C1;
  border-radius: 4px;
  border: 2px solid #71B3C1;
  color: #FFF;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: not-allowed
}

.button-red {
  background-color: #E64A79;
  border-radius: 4px;
  border: 2px solid #E64A79;
  color: #FFF;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.button-red:disabled {
  background-color: #EA86A5;
  border-radius: 4px;
  border: 2px solid #EA86A5;
  color: #FFF;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: not-allowed
}

.button-grey {
  background-color: #A4B7C1;
  border-radius: 4px;
  border: 2px solid #A4B7C1;
  color: #000;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.button-grey:disabled {
  background-color: #A4B7C1;
  border-radius: 4px;
  border: 2px solid #A4B7C1;
  color: #FFF;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: not-allowed
}

.button-grey-inverse {
  background-color: #FFF;
  border-radius: 4px;
  border: 2px solid #A4B7C1;
  color: #000;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.button-grey-inverse:disabled {
  background-color: #FFF;
  border-radius: 4px;
  border: 2px solid #A4B7C1;
  color: rgba(0, 0, 0, 0.239);
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: not-allowed
}

.button-green-inverse {
  background-color: #FFF;
  border-radius: 4px;
  border: 2px solid #238A9F;
  color: #238A9F;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.button-green-inverse:disabled {
  background-color: #71B3C1;
  border-radius: 4px;
  border: 2px solid #71B3C1;
  color: #FFF;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: not-allowed
}

.button-primary-inverse {
  background-color: #FFF;
  border-radius: 4px;
  border: 2px solid #555194;
  color: #555194;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.button-primary-inverse:disabled {
  background-color: #E2E1E4;
  border-radius: 4px;
  border: 2px solid #6A6972;
  color: #6A6972;
  padding: 2px 20px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: not-allowed
}
// BUTTONS : END

// TABS: START
.custom-tabs {
  .tab-content {
    background-color: #F4F4F4;
  }

  .tab-content .tab-pane {
    padding: 10px;
  }

  .nav-tabs {
    li {
      .nav-link.active {
        color: #555194;
        border: 0;
        font-weight: 500;
        border-bottom: 2px solid;
      }

      .nav-link:hover {
        color: #555194;
        background-color: #55519424;
        border: 0;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border: 1px solid #55519400;
        cursor: pointer;
      }

      .nav-link.active:hover {
        color: #555194;
        border: 0;
        font-weight: 500;
        border-bottom: 2px solid;
        cursor: pointer;
      }
    }
  }

  .nav-pills {
    li {
      .nav-link {
        padding: 0.5rem 1rem;
        border: 1px solid #55519400;
      }

      .nav-link.active {
        color: #555194;
        border: 0;
        font-weight: 500;
        border-bottom: 2px solid;
      }

      .nav-link:hover {
        color: #555194;
        background-color: #55519424;
        border: 0;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border: 1px solid #55519400;
        cursor: pointer;
      }

      .nav-link.active:hover {
        color: #555194;
        border: 0;
        font-weight: 500;
        border-bottom: 2px solid;
        cursor: pointer;
      }
    }
  }
}

// TABS: END

// TABLE : START
.custom-table {
  width: 100%;
  background-color: #FFF;

  thead {
    background-color: #555194;

    tr {
      th {
        padding: 5.5px 10px;
        text-transform: uppercase;
        color: #FFF;
        font-weight: 500;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e4e5e6;

      td {
        padding: 5.5px 10px;
      }

      .wrapper-status {
        display: block;
        text-align: center;
        border-radius: 5px;
        padding: 2px 0;
        width: 80%;
      }

      .status-grey {
        background-color: #9B9B9B;
        color: #FFF;
      }

      .status-orange {
        background-color: #f39c12;
        color: #FFF;
      }

      .status-green {
        background-color: #74b52d;
        color: #FFF;
      }

      .status-red {
        background-color: #e74c3c;
        color: #FFF;
      }

      .status-blue {
        background-color: #0094c9;
        color: #FFF;
      }
    }
  }

  .tw-60 {
    width: 60px;
  }
  
  .tw-90 {
    width: 90px;
  }

  .tw-100 {
    width: 100px;
  }

  .tw-350 {
    width: 350px;
  }
}

.custom-table.custom-table-light {
  thead {
    background-color: #DAD9E8;

    tr {
      th {
        padding: 5.5px 10px;
        text-transform: uppercase;
        color: #555194;
        font-weight: 500;
      }
    }
  }
}

// TABLE : END

// FORM CONTROL : START
.wrapper-form-control {
  label {
    color: #565294;
    display: block !important;
    margin-bottom: 0;
  }

  .input {
    width: 100%;
    display: inline-block;
    border: 2px solid #c2cfd6;
    padding: 5.5px 10px;
    border-radius: 5px;
  }

  input:disabled {
    width: 100%;
    display: inline-block;
    background-color: #F0F3F5;
    border: 2px solid #c2cfd6;
    padding: 5.5px 10px;
    border-radius: 5px;
  }

  input[readonly] {
    width: 100%;
    display: inline-block;
    background-color: #F0F3F5;
    border: 2px solid #c2cfd6;
    padding: 5.5px 10px;
    border-radius: 5px;
  }

  input:focus {
    outline: 0;
  }

  input::placeholder {
    color: #9A9A9A;
  }

  input.ng-invalid.ng-touched, textarea.ng-invalid.ng-touched {
    width: 100%;
    display: inline-block;
    border: 2px solid red;
    padding: 5.5px 10px;
    border-radius: 5px;
  }

  .input.ng-valid {
    width: 100%;
    display: inline-block;
    border: 2px solid #c2cfd6;
    padding: 5.5px 10px;
    border-radius: 5px;
  }
}

// FORM CONTROL : END

// SELECT : START
ng-select.ng-select-multiple.custom-select-new {
  .ng-select-container {
    min-height: initial !important;
    border: 2px solid #c2cfd6;
    border-radius: 5px;

    .ng-value-container {
      padding: 3px;

      .ng-value {
        margin-bottom: 1px;
      }

      .ng-value+.ng-input {
        width: 0px;
      }
    }
  }
}

ng-select.custom-select-new {
  height: auto;

  .ng-select-container {
    min-height: initial !important;
    border: 2px solid #c2cfd6;
    border-radius: 5px;
  }
}

ng-select.custom-select-new.ng-invalid.ng-touched {
  height: auto;

  .ng-select-container {
    min-height: initial !important;
    border: 2px solid red;
    border-radius: 5px;
  }
}

ng-select.custom-select-new.ng-valid {
  height: auto;

  .ng-select-container {
    min-height: initial !important;
    border: 2px solid #c2cfd6;
    border-radius: 5px;
  }
}

ng-select.ng-select-multiple.custom-select-new.special-case {
  .ng-select-container {
    min-height: initial !important;
    border: 1px solid #c2cfd6;
    border-radius: 0px;

    .ng-value-container {
      padding: 0px !important;

      .ng-value {
        margin-bottom: 1px;
      }

      .ng-value+.ng-input {
        width: 0px;
      }

      .ng-placeholder {
        top: 3px !important;
      }
    }
  }
}

// SELECT: END

// CURVY TABS : START
.wrapper-card-tab {
  display: inline-block;
  padding-top: 20px;

  .tab {
    margin: 0;
    padding: 10px 25px;
    color: #747379;
    display: inline-block;
    font-size: 15px;
    border: 1px solid #7b7a7f6b;
    border-bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .tab-active {
    background-color: #FFF;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: #555194;
  }

  .tab:hover {
    cursor: pointer;
  }

  .card {
    border-top-left-radius: 0;

    .card-body {
      padding: 20px;
    }
  }
}

// CURVY TABS : END

// NGX-TABLE : START
.ngx-datatable.custom-upgraded .datatable-body .datatable-row-wrapper {
  border-bottom: 1px solid #00000017;
}

.ngx-datatable.custom-upgraded .datatable-footer {
  border-top: 0 !important;
}

.ngx-datatable.custom-upgraded .datatable-header .datatable-header-cell {
  padding: 5.5px 10px !important;
}

.ngx-datatable.custom-upgraded .datatable-body-row .datatable-body-cell {
  padding: 5.5px 10px !important;
}

.ngx-datatable .datatable-footer {
  overflow: hidden !important;
}

.ngx-datatable.material {
  box-shadow: none !important;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  color: #FFF;
  background-color: #555194;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  text-transform: uppercase;
}

.ngx-datatable.material .datatable-header {
  border: 0 !important;
}

// NGX-TABLE : END

// CARD PRIMARY : START 
.card-custom {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 8%);
  border: 1px solid #5551942e;
  border-radius: 10px;
  height: auto;
  margin-bottom: 10px;
  background-color: #FFF;

  .card-header {
    padding: 3px 10px;
    background-color: #555194;
    color: #FFF;
    font-weight: 500;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-transform: uppercase;
  }

  .card-body {
    padding: 10px;
  }
}

// CARD PRIMARY : END

// CARD LIGHT : START
.card-custom-light {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 8%);
  border: 1px solid #DAD9E8;
  border-radius: 10px;
  height: auto;
  margin-bottom: 10px;
  background-color: #FFF;

  .card-header {
    padding: 3px 10px;
    background-color: #DAD9E8;
    color: #555194;
    font-weight: 500;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-transform: uppercase;
  }

  .card-body {
    padding: 10px;
  }
}

// CARD LIGHT : START

// TABLE ACTIONS : START
.action-drop-down {
  left: -150px !important;
}

.wrapper-table-action {
  padding-left: 30px;

  .img-table-action {
    width: 22px;
  }

  .img-table-action:hover {
    cursor: pointer;
  }
}

// TABLE ACTIONS : END
